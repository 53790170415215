.slick-slider {
    height: 100%;
}
.slick-list {
    height: 100%;
}
.slick-track {
  display: flex;
  height: 100%;
}
.slick-track .slick-slide {
  display: flex;
  height: '100%';
}

.slick-slide > div {
    height: 100%;
}

.slide-container {
    height: 100%;
    position: relative;
    display: inline-block;
    overflow: hidden;
    margin: 0;
}

.slide-container > img {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  transform: translate(-50%, -50%);
}

.grecaptcha-badge {
  visibility: hidden;
}
